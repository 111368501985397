import { Device } from "../devices/slice";
import React from 'react';
import { Chip } from 'primereact/chip';
import { shallowEqual } from "./helpers";

export const ConfigStatus = (props: { device: Device }) => {
    var classN;
    var label;

    if (props.device?.next_config?.pending === false && props.device.current_config?.pending === false && shallowEqual(props.device.current_config, props.device.next_config)) {
        classN = "custom-chip status-confirmed";
        label = "Confirmed";
    }
    else if (props.device.next_config?.pending === true) {
        classN = "custom-chip status-pending";
        label = "Pending";
    }
    else if (props.device.current_config?.pending === true) {
        classN = "custom-chip status-sent";
        label = "Pending";
    }
    return <Chip label={label} className={classN}/>

}

export const ChangingItem = (item: string, device: Device) => {
    //@ts-ignore
    if ((device.current_config.pending === false && device.next_config.pending === false) || device.current_config[item] == device.next_config[item]) {
        if (item === 'mode') return <Chip label={device?.current_config?.mode ?? ''}/>
        //@ts-ignore
        return device.current_config[item]
    }
    if (item === 'mode') return <span>
            <Chip label={device?.current_config?.mode ?? ''}/>
            <i className="pi pi-arrow-right"></i>
            <Chip label={device?.next_config?.mode ?? ''}/>
        </span>
    //@ts-ignore
    return <span><span style={{color: 'grey'}}>{device.current_config[item]}</span> <i className="pi pi-arrow-right"></i> {device.next_config[item]} </span>
}
import ReactMapboxGl, {
  Layer,
  Feature,
  Source,
  GeoJSONLayer,
  Marker,
  ZoomControl,
} from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import * as turf from "@turf/turf";
import { Button } from "primereact/button";
import { useGetUsersQuery, User } from "munityapps/user/slice";
import MunityDataTable from "munityapps/layouts/components/MunityDataTable";
import firebaseConfig from "./firebase.config.js";
//@ts-ignore
import mapboxgl from "mapbox-gl";
import LayerCircle from "./mapComponents/LayerCircle";
import { REACT_APP_MAPBOX_API_KEY } from "./mapbox.config.js";

// @ts-ignore
// // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore();

const GeoLayer = (props: { id: string | undefined }) => {
  const [posLive, setPosLive] = useState([40, 3]);
  const [geoData, setGeoData] = useState<{ features: any; type: string }>({
    features: [],
    type: "",
  });

  useEffect(() => {
    if (props.id) {
      setGeoData({ features: [], type: "" });

      onSnapshot(doc(db, "users", props.id), (doc) => {
        setPosLive([doc?.data()?.pos.longitude, doc?.data()?.pos.latitude]);
      });

      onSnapshot(collection(db, "users", props.id, "cones"), (snap) => {
        var final: any[] = [];

        snap.docs.map((doc) => {
          var from = turf.point([
            doc.data()?.center.latitude,
            doc.data()?.center.longitude,
          ]);
          var distance = 20;
          var bearing1 = -doc.data().angle + 89.9;
          var bearing2 = -doc.data().angle + 90.1;
          // var options = {units: tu};
          var dest1 = turf.destination(from, distance, bearing1);
          var dest2 = turf.destination(from, distance, bearing2);
          final.push({
            origin: from,
            point1: dest1.geometry,
            point2: dest2.geometry,
          });
        });

        var features = final.map((data) => {
          return {
            type: "Feature",
            properties: {
              category: "cat",
            },
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  data.origin.geometry.coordinates.reverse(),
                  data.point1.coordinates.reverse(),
                  data.point2.coordinates.reverse(),
                ],
              ],
            },
          };
        });

        setGeoData({
          type: "FeatureCollection",
          features: features,
        });
      });
    }
  }, [props.id]);

  const polygonPaint = {
    "fill-color": "#ff0000",
    "fill-opacity": 0.8,
  };

  return (
    <>
      <GeoJSONLayer key={"polygone"} data={geoData} fillPaint={polygonPaint} />
      {geoData?.features.map((geo: any, idx: any) => {
        return (
          <LayerCircle
            key={idx}
            center={geo?.geometry?.coordinates[0][0]}
            color="blue"
            radius={25}
            id={idx}
            opacity={0.3}
          />
        );
      })}

      <Layer
        type="circle"
        id="marker"
        paint={{
          "circle-color": "#0000ff",
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
          "circle-stroke-opacity": 1,
          "circle-radius": 16,
          "circle-opacity": 0.5,
        }}
      >
        <Feature coordinates={posLive} />
      </Layer>
    </>
  );
};

const MapComponent: React.FC<{}> = (props) => {
  const [selectedUser, setSelectedUser] = useState<string>();
  const [center, setCenter] = useState<[number, number]>([1.435, 42]);
  const { data: users } = useGetUsersQuery();

  const Map = ReactMapboxGl({
    accessToken:
      process.env.REACT_APP_MAPBOX_API_KEY || REACT_APP_MAPBOX_API_KEY,
  });

  const createNew = () => {};

  const handleUserClicked = async (user: User) => {
    setSelectedUser(user.id);
    const docSnap = await getDoc(doc(db, "users", user?.id));
    if (docSnap.exists()) {
      setCenter([docSnap.data().pos.longitude, docSnap.data().pos.latitude]);
    } else {
      console.log("No such document!");
    }
  };

  const actions = (user: User) => {
    return (
      <div className="relative-open-form-btn action-buttons">
        <Button onClick={() => handleUserClicked(user)} icon="pi pi-eye" />
      </div>
    );
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div className="side-table" style={{ width: "30%" }}>
        <DataTable
          value={users?.results}
          className="data-table"
          dataKey="id"
          filterDisplay="menu"
          globalFilterFields={[
            "id",
            /**'firstname', 'lastname', **/ "mode",
            "created",
          ]}
          emptyMessage={"empty"}
        >
          <Column
            field="username"
            header={"username"}
            filter
            filterPlaceholder="Search by username"
          />
          <Column
            body={actions}
            bodyStyle={{ display: "flex", justifyContent: "flex-end" }}
          />
        </DataTable>
      </div>

      <Map
        center={center}
        style="mapbox://styles/mapbox/streets-v9"
        containerStyle={{
          height: "100%",
          width: "70%",
        }}
      >
        <GeoLayer id={selectedUser}></GeoLayer>
        <ZoomControl />
      </Map>
    </div>
  );
};

export default MapComponent;

import { useGetUsersQuery, User } from "munityapps/user/slice";
import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
// import DeleteButton from "../common/confirmationDeleteButton";
// import OpenFormButton from "../common/openFormButton";
// import { DeviceForm } from "./form";
import { Device, useDeleteDeviceMutation, useGetDevicesQuery } from "./slice";
import './style.scss';
import { useEffect, useState } from "react";
import { getURLForFile } from "munityapps/helper";

import MunityDataTable from 'munityapps/layouts/components/MunityDataTable';
import { DeviceForm } from "./form";
import { ChangingItem, ConfigStatus } from "../configs/status";

const DevicesList = (props: {devices: Device[], onDeviceClick: Function})  => {
    const [deleteDevice, { isLoading: isDeleting, isError: deleteError, isSuccess: deleteSuccess }] = useDeleteDeviceMutation();
    const { data: devices } = useGetDevicesQuery();
    const [isVisible, setIsVisible] = useState(false);
    const [editDevice, setEditDevice] = useState<Device | null>(null);
    

    const handleEditDevice = (device: Device) => {
        var editDevice: Device = {
            id: device.id,
            name: device.name,
            firmware: device.firmware,
            hardware: device.hardware,
            sigfox_id: device.sigfox_id
        }
        setEditDevice(editDevice);
        setIsVisible(true)
    }

    const actions = (device: Device) => {
        return <div className='relative-open-form-btn action-buttons'>
        {/* <OpenFormButton action="update" model="device" header={'Editer un stagiaire'} form={<DeviceForm device={device} closeModal={() => null} step={null}/>} />
        <DeleteButton model="device" deleteFunction={() => deleteDevice(device.id)}/> */}
        <Button onClick={() => props.onDeviceClick(device)} icon="pi pi-eye"/>
        <Button onClick={() => handleEditDevice(device)} icon="pi pi-pencil"/>
    </div>
    }

    const mode = (device: Device) => {
        return ChangingItem('mode', device)
    }

    const configStatus = (device: Device) => {
        return <ConfigStatus device={device} />
    }

    const createNew = () => {
        setEditDevice(null);
        setIsVisible(true);
    }


    return <>
            <Dialog header="Header" visible={isVisible} onHide={() => {setIsVisible(false); setEditDevice(null)}}>
                <DeviceForm 
                    device={editDevice}
                    onSuccess={() => setIsVisible(false)}
                />
            </Dialog>
            <MunityDataTable
                createNew={createNew}
                value={props.devices}
                className="data-table"
                dataKey="id"
                filters={{
                    // 'id': { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
                    'mode': { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
                }}
                filterDisplay="menu"
                globalFilterFields={['name', /**'firstname', 'lastname', **/'mode', 'created']}
                emptyMessage={'No devices'}
            >
                <Column field="name" header={'Name'} filterPlaceholder="Search by username" />
                <Column body={mode} field="mode" header={'mode'} filter filterPlaceholder="Search by username"/>
                <Column body={configStatus} field="status" header={'status'} filter filterPlaceholder="Search by username"/>
                <Column body={actions} bodyStyle={{ display: 'flex', justifyContent: 'flex-end' }} />
            </MunityDataTable>
        </>

}

export default DevicesList;
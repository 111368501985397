import { Device, useGetDeviceQuery } from "./slice";
import { useState } from "react";
import DeviceList from "./list";
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';
import { DeviceForm } from "./form";
import { Button } from "primereact/button";
import { ConfigForm } from "../configs/form";
import { ChangingItem, ConfigStatus } from "../configs/status";
import { useTranslation } from "react-i18next";
import './style.scss';

const DeviceDetails = (props: {device: Device | undefined, refreshDevices: Function}) => {
    const [isVisible, setIsVisible] = useState(false);
    const {data: device, refetch} = useGetDeviceQuery(props.device?.id);
    const { t } = useTranslation();

    return device ? <div>
            <Dialog header="Edit configuration" visible={isVisible} onHide={() => {setIsVisible(false);}}>
                <ConfigForm 
                    config={device.next_config}
                    onUpdateConfig={() => {refetch(); setIsVisible(false); props.refreshDevices()}}
                />
            </Dialog>
            <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                <h1>
                    {device?.name}
                </h1>
                <div style={{marginTop: '16px'}}>
                    <ConfigStatus device={device}/>
                </div>
            </div>
            <div className="p-grid device-details-wrapper">
                <div className={'p-col-6 p-md-6'}>
                    <div>
                        Hardware : {device?.hardware}
                    </div>
                    <div>
                        Firmware : {device?.firmware}
                    </div>
                    <div>
                        Sigfox ID: {device?.sigfox_id}
                    </div>
                    <div>
                        Mode: {ChangingItem('mode', device)}
                    </div>
                    <div>
                        LQI: {device.lqi}
                    </div>
                    <div>
                        Battery Voltage: {device.battery_voltage ? `${device.battery_voltage} V` : ''}
                    </div>
                </div>
                <div className={'p-col-6 p-md-6'}>
                    <div>
                        Owley Freq : {ChangingItem('owley_frequency', device)}
                    </div>
                    <div>
                        Owley Schema : {ChangingItem('owley_scheme', device)}
                    </div>
                    <div>
                        Owley TX Power : {ChangingItem('owley_tx_power', device)}
                    </div>
                    <div>
                        Sigfox TX Period : {ChangingItem('sigfox_tx_period', device)}
                    </div>
                </div>
            </div>
            <Button onClick={() => setIsVisible(true)} label="Change Config"/>
    </div>
    :
    <h1>{t('common:select_device')}</h1>
}


export default DeviceDetails;
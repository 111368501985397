import 'react-toastify/dist/ReactToastify.css';

import iconOvmAdmin from 'munityapps/assets/icon_ovm_admin.svg';
import iconOvmDeploy from 'munityapps/assets/icon_ovm_deploy.svg';
import { Button } from 'primereact/button';
import { useAppDispatch } from 'munityapps/hooks';
import { addNotification } from 'munityapps/notifications/slice';
import { useHistory, useLocation } from 'react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { faDiscord, faGitAlt } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OvermindSidebar: React.FC<{ newMenuButton?: Partial<React.Component>[] }> = props => {

    const dispatch = useAppDispatch();
    const location = useLocation();
    const history = useHistory();

    const [showGitPopup, setShowGitPopup] = React.useState<boolean>(false);

    const { t } = useTranslation();

    return <div className="sidebar">
        <div className="menu">
            <Button className={`p-button-link ${location.pathname.match(/^\/workspaces/g) ? 'active' : null}`} onClick={() => history.push('/workspaces')}>
                <img style={{ marginRight: '30px' }} src={iconOvmDeploy} alt="graph" /> {t('common:customers')}
            </Button>
        </div>
        <div className="menu">
            <Button className={`p-button-link ${location.pathname.match(/^\/users/g) ? 'active' : null}`} onClick={() => history.push('/users')}>
                <img src={iconOvmAdmin} alt="graph" /> {t('common:administrator')}
            </Button>
        </div>
        {props.newMenuButton}
    </div>
}

export default OvermindSidebar;
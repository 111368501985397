import { Workspace } from 'munityapps/workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from 'munityapps/factory/slice';
import { Config } from '../configs/slice';

export interface Device {
    id: string,
    name: string,
    sigfox_id?: string,
    sigfox_radio_configuration?: string,
    current_config?: Config,
    next_config?: Config,
    hardware?: string,
    firmware?: string,
    last_seen?: Date,
    position_latitude?: number,
    position_longitude?: number,
    position_radius?: number,
    lqi?: string,
    battery_voltage?: number,
    created?: Date,
    modified?: Date,
    generic_groups?: string[],
    workspace?: Workspace | null
}

export interface DeviceState {
    deviceInEdition: Device | null
}

export const initialState: DeviceState = {
    deviceInEdition: null
}

export const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        setDeviceInEdition: (state, payload:{payload:Device|null}) => {
            state.deviceInEdition = payload.payload;
        }
    }
});

export const deviceAPISlice = sliceFactory<Device>({
    reducerName: 'deviceAPI',
    endpoint: '/devices/',
    name: 'Device'
});


export default deviceSlice.reducer;
export const { setDeviceInEdition } = deviceSlice.actions

export const {
    useGetDevicesQuery,
    useGetDeviceQuery,
    useDeleteDeviceMutation,
    useUpdateDeviceMutation,
    useCreateDeviceMutation,
} = deviceAPISlice

export interface Firmware {
    id: string,
    version: string
}

export interface Hardware {
    id: string,
    version: string,
    firmwares: Firmware[]
}

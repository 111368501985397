export const shallowEqual = (current_config, next_config) => {
    const keys1 = Object.keys(current_config);
    const keys2 = Object.keys(next_config);
    if (keys1.length !== keys2.length) {
      return false;
    }
    if (current_config.cdevice != next_config.ndevice) {
        return false;
    }
    for (let key of keys1) {
        if (key != 'id' && key != 'modified' && key != 'created' && key != 'cdevice' && key != 'ndevice' && key != 'generic_groups'){
            if (current_config[key] !== next_config[key]) {
                return false;
            }
        }
    }
    return true;
}
// Libs
import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router';

// Component
import MunityApp from 'munityapps/app';
import Navbar from 'munityapps/workspaces/components/Navbar';
import NavbarLeft from './Navbar/NavbarLeft';
import NavbarCenter from './Navbar/NavbarCenter';
import NavbarRight from './Navbar/NavbarRight';
import Workspaces from 'munityapps/workspaces/list'

// Configuration
import reportWebVitals from './reportWebVitals';

// Boilerplate
import { Provider as ReduxProvider } from 'react-redux';
import Providers from './providers';
import OvermindSidebar from './sidebar';

// Style
import 'munityapps/styles.scss';
import './styles.scss';

import logo from './assets/owley.png';

import store from './store';
import MapComponent from './Map';
import Devices from './devices';
import LoadingOwley from './loading';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

console.log('A NE PAS FAIRE EN PROD');

ReactDOM.render(
    <ReduxProvider store={store}>
        <I18nextProvider i18n={i18n}>
            <Providers>
                <React.StrictMode>
                    <MunityApp
                        overmindNavbar={<Navbar
                            leftPart={NavbarLeft}
                            centerPart={NavbarCenter}
                            rightPart={NavbarRight}
                        />}
                        logoLogin={logo}
                        workspaceNavbar={<Navbar
                            leftPart={NavbarLeft}
                            centerPart={NavbarCenter}
                            rightPart={NavbarRight}
                        />}
                        overmindSidebar={<OvermindSidebar />}
                        newOvermindRoutes={[
                            <Route exact key={'workspace'} path="/" component={Workspaces}/>
                        ]}
                        newWorkspaceRoutes={[
                            <Route key={'map'} path="/workspace/:slug/map" component={MapComponent} />,
                            <Route key={'devices'} path="/workspace/:slug/devices" component={Devices} />
                        ]}
                        loadingWorkspace={LoadingOwley}
                    >
                    </MunityApp>
                </React.StrictMode>
            </Providers>
        </I18nextProvider>
    </ReduxProvider >,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberValueChangeParams } from "primereact/inputnumber";
import { Fieldset } from "primereact/fieldset";
import { useCreateConfigMutation, useGetConfigsQuery, useDeleteConfigMutation, Config, useUpdateConfigMutation } from "./slice";
import { addNotification } from "munityapps/notifications/slice";
import { useAppDispatch } from "munityapps/hooks";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';

const initialConfigState = {
    id: "",
    mode: "normal",
    owley_frequency: 1,
    owley_scheme: 1,
    owley_tx_power: 1,
    sigfox_tx_period: 5,
};

export const ConfigForm = (props: { config: Config | null, onUpdateConfig: Function }) => {
    const dispatch = useAppDispatch();

    const [config, setConfig] = useState<Pick<Config, 'id' | 'mode' | 'owley_frequency' | 'owley_scheme' | 'owley_tx_power' | 'sigfox_tx_period'>>(initialConfigState);
    const { isFetching: loadingConfig } = useGetConfigsQuery();
    const [createConfig, { isLoading: isCreating, isError: createError, isSuccess: createSuccess }] = useCreateConfigMutation();
    const [updateConfig, { isLoading: isUpdating, isError: updateError, isSuccess: updateSuccess }] = useUpdateConfigMutation();
    const [deleteConfig, { isLoading: isDeleting, isError: deleteError, isSuccess: deleteSuccess }] = useDeleteConfigMutation();

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputNumberValueChangeParams) => {
        setConfig((prev) => ({
            ...prev,
            pending: true,
            [target.id]: target.value,
        }));
    }

    const handleDropdownChange = ({ target }: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputNumberValueChangeParams) => {
        setConfig((prev) => ({
            ...prev,
            pending: true,
            [target.id]: target.value.id,
        }));
    }

    let action = "";
    if (loadingConfig) {
        action = "Loading configs";
    }

    if (isCreating) {
        action = "Creating configs";
    }

    if (isUpdating) {
        action = "Updating configs";
    }

    if (isDeleting) {
        action = "Deleting configs";
    }

    useEffect(() => {
        setConfig(props.config || initialConfigState);
    }, [props.config])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Cannot update config'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Config updated'
            }));
            props.onUpdateConfig();
        }
    }, [updateSuccess, dispatch]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Cannot delete config'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Config deleted'
            }));
            setConfig(initialConfigState);
        }
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Config created'
            }));
        }
    }, [createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Cannot create config'
            }));
        }
    }, [createError, dispatch]);

    const modeOptions = [{'label': 'normal', 'id': 'normal'}, {'label': 'rescue', 'id': 'rescue'}];

    return <div className="p-grid p-m-4 p-jc-between p-flex-row">
        <Fieldset legend={props.config ? `Editing` : 'Creating config'}>
            <div className="p-formgrid p-grid">
                <label htmlFor="mode" className={'p-col-12 p-md-4'}>Mode</label>
                <div className="p-field p-col-12 p-md-6">
                    <Dropdown id="mode" value={modeOptions.find((el) => el.id ===  config.mode)} options={modeOptions} onChange={handleDropdownChange} />
                </div>
            </div>
            <div className="p-field p-grid">
                    <label htmlFor="description" className={'p-col-12 p-md-4'}>Fréquence (Hz)</label>
                    <div className={'p-col-12 p-md-6'}>
                        <InputNumber id="owley_frequency" mode="decimal" useGrouping={false} onValueChange={(e) => { 
                            setConfig((prev) => ({
                                ...prev,
                                owley_frequency: e.value ? e.value : undefined,
                                pending: true
                            }));}
                            } 
                        value={config.owley_frequency} /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="description" className={'p-col-12 p-md-4'}>Schéma d'émission Owley</label>
                    <div className={'p-col-12 p-md-6'}>
                        <Dropdown id="owley_scheme" value={{label: config.owley_scheme, id: config.owley_scheme}} options={Array.from(Array(3).keys()).map((e) => ({label: e + 1, id: e + 1}))} onChange={handleDropdownChange}/><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="description" className={'p-col-12 p-md-4'}>TX Power (dB)</label>
                    <div className={'p-col-12 p-md-6'}>
                        <Dropdown id="owley_tx_power" value={{label: config.owley_tx_power, id: config.owley_tx_power}} options={Array.from(Array(16).keys()).map((e) => ({label: e, id: e}))} onChange={handleDropdownChange}/><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <label htmlFor="description" className={'p-col-12 p-md-4'}>Sigfox TX Period (min)</label>
                    <div className={'p-col-12 p-md-6'}>
                        <InputNumber id="sigfox_tx_period" min={5} max={1275} mode="decimal" useGrouping={false} onValueChange={(e) => { 
                            setConfig((prev) => ({
                                ...prev,
                                sigfox_tx_period: e.value ? e.value * 60 : undefined,
                                pending: true
                            }));}
                        } 
                        value={config.sigfox_tx_period ? config.sigfox_tx_period / 60 : undefined} /><br />
                    </div>
                </div>
            <Button onClick={() => props.config ? updateConfig(config) : createConfig(config)} >{props.config ? `Edit` : 'Create config'} </Button>
        </Fieldset>
    </div>;
}
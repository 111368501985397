import React, { useState, useEffect, memo } from 'react';
import { GeoJSONLayer } from 'react-mapbox-gl';

const createGeoJSONCircle = function (center: number[], radiusInKm: number) {
    const ret = [];
    const distanceX = radiusInKm / (111.320 * Math.cos(center[1] * Math.PI / 180));
    const distanceY = radiusInKm / 110.574;
    let theta; let x; let
        y;
    for (let i = 0; i < 64; i++) {
        theta = i / 64 * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([center[0] + x, center[1] + y]);
    }
    ret.push(ret[0]);

    return {
        type: 'FeatureCollection',
        features: [{
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [ret]
            }
        }]
    };
};

const LayerCircle = (props: {id: string, center: number[], radius: number, color: string, opacity: number}) => {
    const [geoJSONData, setGeoJSONData] = useState({
        type: 'FeatureCollection',
        features: []
    });

    useEffect(() => {
        //@ts-ignore
        setGeoJSONData(createGeoJSONCircle(props.center, props.radius / 1000.0));
    }, [props.center, props.radius]);

    return (
        <GeoJSONLayer
            id={`geojsonlayer-circle-${props.id}`}
            data={geoJSONData}
            type="fill"
            fillPaint={{
                'fill-color':props.color,
                'fill-opacity': props.opacity
            }}
        />
    );
};

export default memo(LayerCircle);

import { Workspace } from 'munityapps/workspaces/slice';
import { createSlice } from '@reduxjs/toolkit';
import { sliceFactory } from 'munityapps/factory/slice';

export interface Config {
    id: string,
    disabled?: boolean,
    cdevice?: string,
    ndevice?: string,
    owley_frequency?: number,
    owley_scheme?: number,
    owley_tx_power?: number,
    sigfox_tx_period?: number,
    mode?: string,
    pending?: boolean,
    created?: Date,
    modified?: Date,
    generic_groups?: string[],
    workspace?: Workspace | null
}

export interface ConfigState {
    configInEdition: Config | null
}

export const initialState: ConfigState = {
    configInEdition: null
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfigInEdition: (state, payload:{payload:Config|null}) => {
            state.configInEdition = payload.payload;
        }
    }
});

export const configAPISlice = sliceFactory<Config>({
    reducerName: 'configAPI',
    endpoint: '/configs/',
    name: 'Config'
});


export default configSlice.reducer;
export const { setConfigInEdition } = configSlice.actions

export const {
    useGetConfigsQuery,
    useGetConfigQuery,
    useDeleteConfigMutation,
    useUpdateConfigMutation,
    useCreateConfigMutation,
} = configAPISlice
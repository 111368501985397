import { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Fieldset } from "primereact/fieldset";
import { useCreateDeviceMutation, useGetDevicesQuery, useDeleteDeviceMutation, Device, useUpdateDeviceMutation, Hardware } from "./slice";
import { addNotification } from "munityapps/notifications/slice";
import { useAppDispatch } from "munityapps/hooks";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import axios from 'axios';
import { getAPIUrl } from "munityapps/helper";

const initialDeviceState = {
    id: "",
    name: "",
    mode: "normal",
    firmware: "",
    hardware: "",
    sigfox_id: ""
};

export const DeviceForm = (props: { device: Device | null, onSuccess: Function }) => {
    const dispatch = useAppDispatch();

    const [device, setDevice] = useState<Pick<Device, 'id' | 'firmware' | 'hardware' | 'sigfox_id' | 'name'>>(initialDeviceState);
    const { isFetching: loadingDevice } = useGetDevicesQuery();
    const { data: devices } = useGetDevicesQuery();
    const [createDevice, { isLoading: isCreating, isError: createError, isSuccess: createSuccess }] = useCreateDeviceMutation();
    const [updateDevice, { isLoading: isUpdating, isError: updateError, isSuccess: updateSuccess }] = useUpdateDeviceMutation();
    const [deleteDevice, { isLoading: isDeleting, isError: deleteError, isSuccess: deleteSuccess }] = useDeleteDeviceMutation();
    const [hardwares, setHardwares] = useState<Hardware[]>([]);

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams ) => {
        setDevice((prev) => ({
            ...prev,
            [target.id]: target.value,
        }));
    }

    const handleDropdownChange = ({ target }: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams ) => {
        setDevice((prev) => ({
            ...prev,
            pending: true,
            [target.id]: target.value.id,
        }));
    }

    const getHarwares =  async () => {
        const resp = await axios.get(`${getAPIUrl()}/hardwares`,            
        {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`
            }
        })
        setHardwares(resp.data?.results)
    }

    let action = "";
    if (loadingDevice) {
        action = "Loading devices";
    }

    if (isCreating) {
        action = "Creating devices";
    }

    if (isUpdating) {
        action = "Updating devices";
    }

    if (isDeleting) {
        action = "Deleting devices";
    }

    useEffect(() => {
        getHarwares();
    }, [])

    useEffect(() => {
        setDevice(props.device || initialDeviceState);
    }, [props.device])

    // Update alert
    useEffect(() => {
        if (updateError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Cannot update device'
            }));
        }
    }, [updateError, dispatch]);

    useEffect(() => {
        if (updateSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Device updated'
            }));
            props.onSuccess()
        }
    }, [updateSuccess, dispatch]);

    // Delete alert
    useEffect(() => {
        if (deleteError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Cannot delete device'
            }));
        }
    }, [deleteError, dispatch]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Device deleted'
            }));
            setDevice(initialDeviceState);
        }
    }, [deleteSuccess, dispatch]);

    // Create alert
    useEffect(() => {
        if (createSuccess) {
            dispatch(addNotification({
                type: 'success',
                message: 'Device created'
            }));
            props.onSuccess()
        }
    }, [createSuccess, dispatch]);

    useEffect(() => {
        if (createError) {
            dispatch(addNotification({
                type: 'error',
                message: 'Cannot create device'
            }));
        }
    }, [createError, dispatch]);

    const getDeviceHardware = () => {
        let hw = hardwares.find((el) => el.version ===  device.hardware);
        return {label: hw?.version, id: hw?.version}
    }

    const getDeviceFirmware = () => {
        let fw = hardwares.find((el) => el.version ===  device.hardware)?.firmwares.find((el) => el.version ===  device.firmware)
        return {label: fw?.version, id: fw?.version}
    }

    return <div className="p-grid p-m-4 p-jc-between p-flex-row">
        <Fieldset legend={props.device ? `Editing device` : 'Creating device'}>
                <div className="p-field p-grid">
                    <div className={'p-col-12 p-md-10'}>
                    <label htmlFor="description">Name</label>
                        <InputText id="name" onChange={handleChange} value={device.name} /><br />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <div className={'p-col-12 p-md-10'}>
                    <label htmlFor="description">Hardware</label>
                        <Dropdown id="hardware" value={getDeviceHardware()} options={hardwares.map((el) => ({label: el.version, id: el.version}))} onChange={handleDropdownChange} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <div className={'p-col-12 p-md-10'}>
                    <label htmlFor="description">Firmware</label>
                        <Dropdown id="firmware" value={getDeviceFirmware()} options={hardwares.find((el) => el.version ===  device.hardware)?.firmwares.map((el) => ({label: el.version, id: el.version}))} onChange={handleDropdownChange} />
                    </div>
                </div>
                <div className="p-field p-grid">
                    <div className={'p-col-12 p-md-10'}>
                    <label htmlFor="description">Sigfox ID</label>
                        <InputText id="sigfox_id" onChange={handleChange} value={device.sigfox_id} /><br />
                    </div>
                </div>
            <Button onClick={() => props.device ? updateDevice(device) : createDevice(device)} >{props.device ? `Edit` : 'Create device'} </Button>
            {props.device && <Button onClick={() => deleteDevice(device.id)} >Delete device </Button>}
        </Fieldset>
    </div>;
}
import { Device, useGetDevicesQuery } from "./slice";
import { useState } from "react";
import DeviceList from "./list";
import ReactMapboxGl, { ZoomControl } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import DeviceDetails from "./deviceDetails";
import LayerCircle from "../mapComponents/LayerCircle";
import { Button } from "primereact/button";
import "./style.scss";
import { REACT_APP_MAPBOX_API_KEY } from "../mapbox.config.js";
//@ts-ignore
import mapboxgl from "mapbox-gl";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Devices = () => {
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const { data: devices, refetch } = useGetDevicesQuery();

  const Map = ReactMapboxGl({
    accessToken:
      process.env.REACT_APP_MAPBOX_API_KEY || REACT_APP_MAPBOX_API_KEY,
  });

  const handleDeviceClicked = (device: Device) => {
    setSelectedDevice(device);
  };

  return (
    <div
      style={{ display: "flex", width: "100%" }}
      className="devices-list-wrapper"
    >
      <div style={{ display: "flex", width: "100%" }}>
        <div
          id="left-device-list"
          style={{
            width: "50%",
            padding: "0px 32px 0px 0px",
            position: "relative",
          }}
        >
          <Button
            className="refresh-btn"
            onClick={refetch}
            icon="pi pi-refresh"
          />
          <DeviceList
            devices={devices?.results}
            onDeviceClick={(device: Device) => handleDeviceClicked(device)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            height: "100%",
            borderLeft: "1px solid grey",
          }}
        >
          <div style={{ height: "60%", padding: "0px 16px" }}>
            <DeviceDetails device={selectedDevice} refreshDevices={refetch} />
          </div>
          <Map
            zoom={[8]}
            center={
              selectedDevice?.position_latitude &&
              selectedDevice?.position_longitude
                ? [
                    selectedDevice?.position_longitude,
                    selectedDevice?.position_latitude,
                  ]
                : [1.42, 42]
            }
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: "40%",
              width: "100%",
            }}
          >
            {selectedDevice?.position_latitude != undefined &&
            selectedDevice?.position_longitude != undefined &&
            selectedDevice?.position_radius != undefined ? (
              <LayerCircle
                center={[
                  selectedDevice?.position_longitude,
                  selectedDevice?.position_latitude,
                ]}
                color="red"
                radius={selectedDevice?.position_radius}
                id={selectedDevice?.id}
                opacity={0.3}
              />
            ) : (
              <></>
            )}
            <ZoomControl />
          </Map>
        </div>
      </div>
    </div>
  );
};

export default Devices;
